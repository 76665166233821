<template>
  <div class="wet-done-bars-chart">
    <div v-if="$slots.title || title" class="mb-3 d-flex justify-content-between">
      <slot name="title">
        <div class="py-2">{{ title }}</div>
      </slot>
    </div>
    <div v-if="$slots.header" class="mb-3 d-flex justify-content-between">
      <slot name="header" />
    </div>
    <div class="">
      <slot v-if="$slots.content" name="content" />
      <div v-else class="container-ratio-16-9">
        <img
          src="#"
          width="100%"
          height="100%"
          class="empty-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
